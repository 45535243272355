import { useWalletNfts, NftTokenAccount } from "@nfteyez/sol-rayz-react";

import { NftCard } from "../ components/wallet/NftCard";

// const walletPublicKey = "3EqUrFrjgABCWAnqMYjZ36GcktiwDtFdkNYwY6C6cDzy";
const walletPublicKey = process.env.REACT_APP_WALLET;

export const WalletView = (props: any) => {
  const { nfts, isLoading, error } = useWalletNfts({
    publicAddress: walletPublicKey,
  });

  return (
    <div className="my-10">
      {error ? (
        <div>
          <h1>Oops, something went wrong. Please refresh this page.</h1>
          {/* {(error as any)?.message} */}
        </div>
      ) : null}
      <div className={props.onHomepage ? "px-0" : "px-4 2xl:px-0"}>
        {!error && isLoading ? (
          <div>loading...</div>
        ) : (
          <NftList nfts={nfts} error={error} />
        )}
      </div>
    </div>
  );
};

type NftListProps = {
  nfts: NftTokenAccount[];
  error?: Error;
};

const NftList = ({ nfts, error }: NftListProps) => {
  if (error) {
    return null;
  }

  if (!nfts?.length) {
    return <div className="text-center text-2xl pt-16">No NFTs found.</div>;
  }

  let reducedNfts = [];
  for (const nft of nfts) {
    if (nft.data.name !== "EXCHANGE NOTIFICATION NFT") {
      reducedNfts.push(nft);
    }
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-start">
      {reducedNfts?.map((nft) => {
        return <NftCard key={nft.mint} details={nft} onSelect={() => {}} />;
      })}
    </div>
  );
};
