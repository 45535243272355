import { withRouter } from "react-router";
import Goals from "../ components/goals/Goals";
import SectionBox from "../ components/layout/SectionBox";
import SectionText from "../ components/layout/SectionText";
import SectionTitle from "../ components/layout/SectionTitle";
import Separator from "../ components/layout/Separator";
import { WalletView } from "./WalletView";
// import Countdown from "react-countdown";

function Homepage(props: any) {
  // const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  //   return (
  //     <span className="make-font-500 text-xl ">
  //       {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
  //     </span>
  //   );
  // };
  return (
    <div>
      <SectionBox>
        <div style={{ position: "relative" }}>
          <div
            className="hidden xl:block"
            style={{ position: "absolute", top: "-17px", right: "0" }}
          >
            <img
              alt="alt"
              src="davinci.svg"
              // src={placeholder}
              className=" w-full h-auto md:h-36 mx-auto"
            />
          </div>
          <div className="make-font-600 text-3xl md:text-4xl">
            GM, WE ARE DAVINCI DAO.
          </div>
          <div className="make-font-600 text-3xl md:text-4xl">
            THE FIRST (ART) COLLECTOR DAO ON SOLANA.
          </div>
          <div className="make-font-500 text-xl leading-7">
            DavinciDAO's mission is to foster a long-term-oriented space for art
            collectors in the Solana ecosystem.
          </div>
        </div>
      </SectionBox>
      <Separator />
      {/* <SectionBox>
        <div className="mb-4 make-font-500 text-xl md:text-left">
          First Davinci DAO membership auction
        </div>
        <div
          onClick={() => window.open("https://davinci.holaplex.com", "_blank")}
          className="mb-4 text-center transition duration-200 ease-in-out hover:bg-black hover:text-beige-100 make-font-500 text-xl cursor-pointer"
        >
          davinci.holaplex.com
        </div>
        <div className="md:text-right">
          <Countdown
            date={"14 Nov 2021 20:00:00 GMT"}
            onMount={({ completed }) => completed}
            renderer={renderCounter}
          />
        </div>
      </SectionBox>
      <Separator /> */}
      <SectionBox>
        <div className="make-font-600 text-3xl md:text-4xl">
          WE CURATE EXISTING ART,
        </div>
        <div className="make-font-600 text-3xl md:text-4xl">
          COMMISSION NEW WORKS AND
        </div>
        <div className="make-font-600 text-3xl md:text-4xl">
          FACILITATE DISCUSSIONS ON THE FUTURE OF ON-CHAIN ART.
        </div>
      </SectionBox>

      <Separator />
      <SectionBox>
        <div className="make-font-600   text-left text-3xl md:text-4xl ">
          TIMES HAVE CHANGED,
        </div>
        <div className="make-font-600 text-left  text-3xl md:text-4xl mb-8">
          THE NEXT GENERATION OF ART IS COMING ON-CHAIN.
        </div>
        <div className="grid grid-cols-1  md:grid-cols-5">
          <div className="md:col-span-2 make-font-500   text-left md:pr-8 h-full  flex  items-center">
            <div>
              <div className="mb-6">
                Our fundamental thesis is that Solana needs a space for
                long-term collectors.
              </div>
              <div className="mb-6">
                A space for people that are in it for the long-term value
                accrual and appreciation of art over short-term flipping.
              </div>
              <div className="mb-6">
                A space for artists and collectors to shape the future of art.
              </div>
              <div className="mb-6">Davinci DAO aims to be that space.</div>
            </div>
          </div>
          <div className="md:col-span-3 mt-4 md:mt-0 md:pl-16 ">
            <div className="text-left make-font-400 text-xs mb-1">
              @ L A R V A L A B S
            </div>
            <img
              alt="alt"
              src="larvawide.png"
              // src={placeholder}
              className=" w-full h-auto  mx-auto"
            />
          </div>
        </div>
      </SectionBox>
      <Separator />
      <SectionBox>
        <Goals />
      </SectionBox>
      <Separator />
      <SectionBox>
        <SectionTitle>WHY "DAVINCI" DAO?</SectionTitle>
        <div className="text-left">
          <SectionText>
            Leonardo da Vinci epitomizes the value we see encapsulated in
            on-chain art: a symbiotic interdepenence between art and technology
            that engenders the emergence of novel outputs, genres and standards.
          </SectionText>
          <SectionText className="mt-2">
            As an architect, engineer, painter, scientist and sculptor da Vinci
            was acutely aware of how ostensibly distinct fields could field
            innovations and advancements within each other.
          </SectionText>
          <SectionText className="mt-2">
            We want to embrace this mindset and seek out sometimes uncomfortable
            frontiers of art.
          </SectionText>
        </div>
      </SectionBox>
      <Separator />
      <SectionBox>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="text-left pr-4">
            <SectionTitle>HOW WILL THIS WORK?</SectionTitle>
            <SectionText>
              We will welcome one member every day into the DAO for one year.
            </SectionText>
            <SectionText className="mt-2">
              We will cap the initial group to 365 members to create a
              tight-knit group of collectors and artists.
            </SectionText>
          </div>
          <div className="text-left sm:mt-0 mt-4 sm:pl-4">
            <SectionTitle>PROCESS</SectionTitle>
            <SectionText>
              The proceeds of these auctions will be collectively held by the
              DAO and used to acquire & commission art.
            </SectionText>
            <SectionText className="mt-2">
              The ownership of these pieces will be shared among all DAO
              members.
            </SectionText>
            <SectionText className="mt-2">
              To ensure a more inclusive group, we will skip one auction per
              month and grant this membership as a scholarship based on merit.
            </SectionText>
          </div>
        </div>
      </SectionBox>
      <Separator />
      <SectionBox>
        <SectionTitle>TIMELINE</SectionTitle>
        <img
          alt="alt"
          src="./timeline.png"
          // src={placeholder}
          className=" mb-4 w-full h-auto shadow-xl mx-auto"
        />
      </SectionBox>
      <Separator />
      <SectionBox>
        <SectionTitle>COLLECTION</SectionTitle>
        <WalletView onHomepage={true} />
      </SectionBox>
      <Separator />
      {/* <SectionBox>
        <SectionTitle>INSPIRATIONS</SectionTitle>
      </SectionBox> */}
    </div>
  );
}

export default withRouter(Homepage);
