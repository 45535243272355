import { Link } from "react-router-dom";

export default function Navbar(props: any) {
  return (
    <nav className="max-w-screen-2xl mx-auto py-4 px-8 border-black border-b w-full flex items-center ">
      <div>
        <Link className="text-5xl " to="/">
          Davinci DAO
        </Link>
      </div>
      <div className="hidden sm:block text-right flex-grow">
        <Link to="/collection">Collection</Link>
      </div>
    </nav>
  );
}
