import SectionText from "../layout/SectionText";

export default function GoalsColumn(props: any) {
  return (
    <div
      className={`${
        props.borderRight && "border-black md:border-r"
      } text-left mb-8`}
    >
      <img
        alt="alt"
        src={props.src}
        // src={placeholder}
        className=" mb-4 w-full h-auto  mx-auto"
      />
      <div className="px-4">
        <div className="make-font-500 text-3xl">{props.title}</div>
        <SectionText className="mt-16">{props.caption}</SectionText>
      </div>
    </div>
  );
}
