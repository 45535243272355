import Navbar from "./Navbar";
import MainContent from "./Main";
import Footer from "./Footer";

export default function PageWrapper(props: any) {
  return (
    <div className="max-w-screen-2xl mx-auto flex flex-col h-screen">
      <Navbar />
      <MainContent>{props.children}</MainContent>
      <Footer>DM @DAVIDSPACS</Footer>
    </div>
  );
}
