import { Route, Switch } from "react-router";
import Homepage from "./pages/Homepage";
import PageWrapper from "./ components/layout/PageWrapper";
import { WalletView } from "./pages/WalletView";

export default function MainRouter() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <PageWrapper>
            <Homepage />
          </PageWrapper>
        </Route>
        <Route exact path="/collection">
          <PageWrapper>
            <WalletView onHomepage={false} />
          </PageWrapper>
        </Route>
        {/* <Route exact path="/collections">
          <PageWrapper>
            <Collections />
          </PageWrapper>
        </Route>
        <Route path="/collections/:id">
          <PageWrapper>
            <CollectionDashboard />
          </PageWrapper>
        </Route> */}
      </Switch>
    </div>
  );
}
