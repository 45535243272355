import SectionTitle from "../layout/SectionTitle";
import GoalsColumn from "./GoalsColumn";

export default function Goals(props: any) {
  return (
    <>
      <SectionTitle>OUR GOALS</SectionTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
        <GoalsColumn
          src="./goals1.png"
          title="CURATE"
          caption="Acquire and curate the most high-quality art on Solana."
          borderRight={true}
        />
        <GoalsColumn
          src="./goals2.png"
          title="DISCUSS"
          caption="Initiate discussions around standards of this new art genre."
          borderRight={true}
        />
        <GoalsColumn
          src="./goals3.png"
          title="CREATE"
          caption="Create content around crypto art and collecting."
          borderRight={true}
        />
        <GoalsColumn
          src="./goals4.png"
          title="COMMISSION"
          caption="Bring the best artists onto Solana by commissioning 1/1 works."
          borderRight={false}
        />
      </div>
    </>
  );
}
